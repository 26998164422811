import Domain from 'helpers/Domain';
import SharedPaymentComponent from 'aa/vue/components/payment/SharedPaymentComponent';
import Step from 'models/steps/Step';
import { AA_PACKAGE_SWITCH } from 'helpers/featureConstants';
import {
    displayFeedbackToUser,
    handleProcessedPayment,
    handleProcessedPaymentError,
} from 'aa/vue/components/payment/signupUtil';
import {
    ENHANCED_PAYMENT_SUMMARY_ENABLED,
    MULTI_SUB_PLAN_PICKER_ENABLED,
    SKIP_EXPLAINER_STEPS,
    CAMPAIGN_SERVICE_ENABLED,
} from 'aa/helpers/featureConstants';

export default class Payment extends Step {
    path = '/account/signup/submitpayment/';
    name = CBS.Registry.Features[ENHANCED_PAYMENT_SUMMARY_ENABLED] ? 'PAYMENT_SUMMARY' : 'PAYMENT';
    requireAuth = true;
    requireNonAuth = false;
    countStep = true;
    props = {
        isEditPayment: false,
        waitingOverlayEnabled: true,
        displayFeedbackToUser,
        handleProcessedPayment,
        handleProcessedPaymentError,
    };

    constructor() {
        super();

        this.component = SharedPaymentComponent;
    }

    async beforeEnter(to, from, next) {
        // Important for if user backs out of paying for one plan and chooses another
        this._store.commit('setAutoPromoChecked', false);

        if (CBS.Registry.Features[CAMPAIGN_SERVICE_ENABLED]) {
            this._store.commit('setCampaignOfferCoupon');
        }

        if (this._store.getters['flow/skipPayment'] || this._store.getters['user/isSubscriber']) {
            window.location.href = this._store.getters['flow/destinationUrl'];
            return;
        }

        return super.beforeEnter(to, from, next);
    }

    /**
     * @returns {{path: *}|null}
     */
    checkFlowRedirect(from = null) {
        if (
            Domain.isDomestic() &&
            this._store.getters.featureIsActive(AA_PACKAGE_SWITCH) &&
            !this._store.getters['flow/skipPlan']
        ) {
            if (!this._store.getters['plan/selectedPlan']) {
                if (this._store.getters.featureIsActive(SKIP_EXPLAINER_STEPS)) {
                    return { name: 'PLAN' };
                }
                return { name: 'plan_explainer' };
            }
        }

        if (this._store.getters['flow/isEdu']) {
            if (this._store.getters['user/eduCoupon']) {
                this._store.commit('setPromo', this._store.getters['user/eduCoupon']);
            } else {
                return {
                    path: this._store.getters['flow/getFirstStepForAuth'].path,
                };
            }
        } else if (this._store.getters['flow/isGift'] && !this._store.state.user.isLoggedIn) {
            return {
                path: { name: 'SIGNUP' },
            };
        }

        return null;
    }

    /**
     * @param to
     * @returns {{path: *, name: *}}
     */
    getTrackingArgs(to) {
        let args = super.getTrackingArgs(to);
        let productParams = this._store.getters['getPaymentPageProductParamsForTracking'];

        args.pageTitle = 'Paramount+ Payment Information - ParamountPlus.com';
        args.pageType = 'svod_payment';
        args.pageName = this.path;
        args.userType = this._store.state.user.svod.status;

        args.purchaseProduct = productParams.recurlyCode;
        args.purchaseProductName = this._store.getters.bundleShowtime
            ? productParams.planTitle + ' + Showtime'
            : productParams.planTitle;
        args.purchaseQuantity = '1';
        args.purchaseCategory = productParams.purchaseCategory;
        args.purchasePrice = this._store.getters.bundleShowtime
            ? productParams.showtimeBundleRawPrice()
            : productParams.purchasePrice;
        args.productPricingPlan = productParams.planType ?? productParams.productPricingPlan;
        args.productOfferPeriod = productParams.trialString;
        args.pageName = this.path;

        if (this.plan) {
            if (typeof this.plan.planTier !== 'undefined') {
                args.pickPlanType = this.plan.planTier;
                args.pickPlanSku = this.plan.recurlyCode;
            }

            //assumption: productParams (set for US) may have tracking vars
            //not needed for INTL
            const dynamicPlan = Domain.isInternational() ? this.plan : productParams;
            args = {
                ...args,
                purchaseEventBillingStart: '1',
                productOfferPeriod: this.plan.purchaseOfferPeriod || args.productOfferPeriod,
                purchasePrice: dynamicPlan.rawPrice || args.purchasePrice,
                productPricingPlan: this.plan.planType || args.productPricingPlan,
                purchaseProduct: this.plan.recurlyCode,
                purchaseProductName: dynamicPlan.titleForTracking,
            };
        }
        return args;
    }
}
