export default class BaseFormConfig {
    constructor() {
        this.id = null;
        this.type = null;
        this.label = null;
        this.styleClass = [];
    }

    __setDefaultId(configs = {}) {
        if (!configs.id && configs.label.length > 0) {
            this.id = configs.label.replace(' ', '-');
        }

        this.ref = this.id;
    }
}
