import BaseFormConfig from 'aa/vue/components/account/formConfigs/classes/BaseFormConfig';

export default class CheckboxFormConfig extends BaseFormConfig {
    constructor(configs = {}) {
        super();

        this.id = 'checkbox';
        this.elementId = configs.id || 'element-id';
        this.wrapperClass = 'cbs-checkbox';
        this.checked = false;
        this.styleClass = ['cbs-checkbox-input'];

        Object.assign(this, configs);
    }
}
