const STATUS_ANONYMOUS = 'ANONYMOUS';
const STATUS_REGISTERED = 'REGISTERED';
const STATUS_SUBSCRIBER = 'SUBSCRIBER';
const STATUS_SUSPENDED = 'SUSPENDED';
const STATUS_EX_SUBSCRIBER = 'EX_SUBSCRIBER';
const STATUS_CANCEL_SCHEDULED = 'ACTIVE_CANCELLED';
const STATUS_RESELLER = 'RESELLER';
const STATUS_ACTIVE = 'ACTIVE';

export default class UserStatus {
    static get STATUS_ANONYMOUS() {
        return STATUS_ANONYMOUS;
    }

    static get STATUS_REGISTERED() {
        return STATUS_REGISTERED;
    }

    static get STATUS_SUBSCRIBER() {
        return STATUS_SUBSCRIBER;
    }

    static get STATUS_SUSPENDED() {
        return STATUS_SUSPENDED;
    }

    static get STATUS_EX_SUBSCRIBER() {
        return STATUS_EX_SUBSCRIBER;
    }

    static get STATUS_CANCEL_SCHEDULED() {
        return STATUS_CANCEL_SCHEDULED;
    }

    static get STATUS_RESELLER() {
        return STATUS_RESELLER;
    }

    static get STATUS_ACTIVE() {
        return STATUS_ACTIVE;
    }
}
