import RadioboxElm from 'atoms/RadioboxElm';
import ButtonCta from 'atoms/ButtonCta';
import PlanDisplay from 'aa/vue/components/account/pages/accountSwitchPlan/variants/parts/PlanDisplay';
import { mapState, mapGetters } from 'vuex';
import NotificationBarModel from 'aa/vue/models/NotificationBarModel';
import AccountService from 'aa/vue/services/AccountService';
import { logType, pageType } from 'aa/services/AccountPageTrackingService';
import { getLocale } from 'services/LocalizationService';
import { AA_XHR_BASE_PATH } from 'aa/vue/constants/aaRoutes';
import Product from 'aa/vue/models/Product';
import Domain from 'helpers/Domain';

export default {
    components: {
        ButtonCta,
        RadioboxElm,
        PlanDisplay,
    },
    data() {
        return {
            isProcessing: false,
            canSwitchPlan: true,
            selectedID: null,
            isValidPromoList: {},
        };
    },
    computed: {
        ...mapState('AccountSwitchPlan', {
            userProduct: (state) => state.userProduct,
            promo: (state) => state.promo,
            authToken: (state) => state.authToken,
        }),

        ...mapGetters('plan', ['getSelectedPlan', 'currentPlan']),

        cancelConfig() {
            return {
                styleClass: ['button', 'secondary'],
                displayText: this.content.get(
                    'ap_switch_plan_negative_cta',
                    this.$getLocale('cancel'),
                ),
                event: this.cancelOnClicked,
                disabled: this.isProcessing,
            };
        },
        submitConfig() {
            return {
                styleClass: ['button', 'primary'],
                displayText: this.content.get(
                    'ap_switch_plan_positive_cta',
                    this.$getLocale('switch_plan'),
                ),
                event: this.submitOnClicked,
                disabled: this.isProcessing || !this.canSwitchPlan,
            };
        },
    },
    watch: {
        products(value) {
            this.applyDefaultSelectedProduct(value);
        },
        selectedID(value) {
            let product = this.products.filter((product) => {
                if (this.userProduct.plan_type === product.plan_type) {
                    return product;
                }
            })[0];

            this.canSwitchPlan = parseInt(value) !== parseInt(product.id);

            if (!this.canSwitchPlan) {
                if (this.submitConfig.styleClass.indexOf('disabled') < 0) {
                    this.submitConfig.styleClass.push('disabled');
                }
            } else {
                if (this.submitConfig.styleClass.indexOf('disabled') > -1) {
                    this.submitConfig.styleClass.splice(
                        this.submitConfig.styleClass.indexOf('disabled'),
                        1,
                    );
                }
            }
        },
    },
    mounted() {
        this.applyDefaultSelectedProduct(this.products);
        AccountService.setHttpService(this.$http);

        this.enhancedPaymentSummaryEnabled =
            this.$store.state.serverData.featureFlag.enhanced_payment_summary_enabled;
    },
    methods: {
        applyDefaultSelectedProduct(products) {
            /** TODO - this.products is never set, which means this code is never run
             * https://paramount.atlassian.net/browse/PPIWEBM-1520
             **/
            if (products && this.selectedID === null) {
                let selectedProduct = {
                    id: null,
                };
                if (this.userProduct.plan_type === products[0].plan_type) {
                    selectedProduct = products.find(
                        (item) => item.plan_type !== this.userProduct.plan_type,
                    );
                } else {
                    selectedProduct = products.find((item) => item.tier === this.userProduct.tier);
                }

                this.selectedID = selectedProduct.id;
            }
        },
        enableBtnSubmit() {
            this.isProcessing = false;
        },
        disableBtnSubmit() {
            this.isProcessing = true;
        },
        cancelOnClicked() {
            this.disableBtnSubmit();
            this.$trackingService.trackAction(this.trackActionName.SWITCH_PLAN_CANCEL);
            this.redirectToAccount();
        },
        submitOnClicked(displayText = '') {
            let url = `${AA_XHR_BASE_PATH}switch/process/`;
            let httpData = this.buildHttpData();
            const {
                selectedProduct,
                isUpgradingToCF,
                isSwitchingToAnnual,
                notificationPageAttributeKey,
                isCrossGrade,
                trackActionType,
            } = this.prepDataForSubmission();

            if (selectedProduct) {
                let params = {
                    plan: selectedProduct.code,
                    direction: selectedProduct?.switchDirection || '',
                };

                if (this.promo && selectedProduct.isValidPromo) {
                    params.couponCode = this.promo;
                }
                httpData = this.buildHttpData(params);

                // if user hasn't seen prompt modal and confirmed yet, show the prompt modal
                if (!this.enhancedPaymentSummaryEnabled && !this.confirmSwitchPlan) {
                    this.switchPlanPrompt();
                    return;
                }

                if (!this.captchaSolved && this.runFraudProtection(this.captchaAction)) {
                    return false;
                } else if (this.formData?.recaptchaToken) {
                    httpData.recaptchaToken = this.formData.recaptchaToken;
                }

                this.disableBtnSubmit();

                const submitTransaction = () => {
                    this.$http.doPost(url, httpData).then((res) => {
                        // START TEST PLOCTOPLUS-1298: google captcha
                        this.trackCaptchaTokenValidationResult(res);
                        // END TEST PLOCTOPLUS-1298: google captcha
                        if (res.success) {
                            // TO BE REIMPLEMENTED
                            if (isCrossGrade) {
                                this.trackCrossgradeSuccess(selectedProduct, displayText);
                            } else {
                                const args = {
                                    pageType: isUpgradingToCF
                                        ? pageType.UPGRADE
                                        : pageType.DOWNGRADE,
                                    ctaText: displayText,
                                    productCurrentSku: this.selectedPlan?.product_code,
                                    productNewSku: selectedProduct?.recurlyCode,
                                };
                                if (isUpgradingToCF) {
                                    args.upgradeConfirm = 1;
                                } else {
                                    args.downgradeConfirm = 1;
                                }

                                this.trackSwitchPlan(selectedProduct, trackActionType, args);
                            }

                            if (Domain.isDomestic()) {
                                this.openNotification(
                                    new NotificationBarModel({
                                        messageKey: notificationPageAttributeKey,
                                    }),
                                );
                            }

                            if (this.promo) {
                                this.removePromoCookieAfterApply();
                            }
                            if (isUpgradingToCF) {
                                this.hideAllAccessUpsellNavItem();
                            }

                            localStorage.setItem('pickedTier', '');

                            const queryParams = !Domain.isDomestic()
                                ? {
                                      notification: 'switch-plan-success',
                                      messageKey: notificationPageAttributeKey,
                                  }
                                : null;
                            this.redirectToAccount(queryParams);
                        } else {
                            // TO BE REIMPLEMENTED
                            // this.trackCrossgradeFailure(res.message);

                            this.openNotification(
                                new NotificationBarModel({
                                    success: false,
                                    message: getLocale('oh_no_something_went_wrong'),
                                }),
                            );

                            this.enableBtnSubmit();
                        }
                    });
                };
                submitTransaction();
            }
        },

        prepDataForSubmission() {
            let notificationPageAttributeKey = '';
            let isUpgradingToCF = false;
            let isSwitchingToAnnual = false;
            let userPlan = this.currentPlan;
            let selectedProduct = this.getSelectedPlan;

            let isCrossGrade = false;
            let trackActionType = '';

            if (selectedProduct) {
                if (userPlan.product_tier === selectedProduct.tier) {
                    isCrossGrade = true;
                } else if (selectedProduct.switchDirection === 'downgrade') {
                    notificationPageAttributeKey = 'switch_success_downgrade';
                    trackActionType = this.trackActionName.SWITCH_PLAN_DOWNGRADE_COMPLETE;
                } else if (selectedProduct.switchDirection === 'upgrade') {
                    notificationPageAttributeKey = 'switch_success_upgrade';
                    isUpgradingToCF = true;
                    trackActionType = this.trackActionName.SWITCH_PLAN_UPGRADE_COMPLETE;
                }

                // user is trying to switch from monthly plan to annual plan (vice versa is not allowed yet)
                if (
                    userPlan.plan_type === Product.TYPE_MONTHLY &&
                    selectedProduct.planType === Product.TYPE_ANNUAL
                ) {
                    notificationPageAttributeKey = 'switch_success_upgrade_annual';

                    isSwitchingToAnnual = true;
                }
            }

            return {
                selectedProduct,
                isUpgradingToCF,
                isSwitchingToAnnual,
                notificationPageAttributeKey,
                isCrossGrade,
                trackActionType,
            };
        },
        hideAllAccessUpsellNavItem() {
            let aaupsellnav = document.querySelector(
                '#global-header-container li.all-access-upsell-button',
            );

            if (aaupsellnav) {
                aaupsellnav.style.display = 'none';
            }
        },
        isValidPromoToSubmit() {
            return this.isValidPromoList[this.selectedID];
        },
        promoSuccess() {
            this.removePromoCookieAfterApply();
        },
        applyPromo(callback = () => {}) {
            if (this.isValidPromoToSubmit()) {
                this.$trackingService.trackAction(this.trackActionName.SWITCH_PLAN_UPGRADE_OFFER);

                let httpData = {
                    tk_trp: this.authToken,
                    coupon: this.promo,
                };

                AccountService.redeemCoupon(httpData)
                    .then((res) => {
                        if (!res.success) {
                            this.openNotification(
                                new NotificationBarModel({
                                    success: false,
                                    message: res.message,
                                }),
                            );
                        }
                        this.removePromoCookieAfterApply();
                        callback();
                    })
                    .catch((err) => {
                        this.openNotification(
                            new NotificationBarModel({
                                success: false,
                                message: err.message,
                            }),
                        );
                        callback();
                    });
            } else {
                callback();
            }
        },
        applyPromoAfterUpgrade() {
            this.applyPromo(this.redirectToAccount);
        },

        removePromoCookieAfterApply() {
            AccountService.removePromoCookie()
                .then((res) => {
                    // nothing to follow up with, cookie was removed
                })
                .catch((err) => {
                    // swallow error
                });
        },
        redirectToAccount(notificationQuery = null) {
            let queryParams = {
                name: 'AccountMain',
                params: { loadServerData: true, updateCurrentPlan: true },
            };
            if (notificationQuery) {
                queryParams.query = notificationQuery;
            }
            this.$router.push(queryParams);
        },
        handleValidPromo(obj) {
            this.isValidPromoList[obj.productId] = obj.isValid;
        },
        trackSwitchPlan(selectedProduct, action, args = {}) {
            this.$trackingService.trackAction(action, {
                ...args,
                productPricingPlan: selectedProduct.planType,
                productCurrentSku: this.currentPlan.product_code,
                pickPlanType: selectedProduct.planTier,
                pickPlanSku: selectedProduct.recurlyCode,
                purchaseProduct: selectedProduct.recurlyCode,
            });
        },
        trackCrossgradeSuccess(selectedProduct, ctaText) {
            let billingInfo = this.$store.getters.getServerDataByKey('billingInfo');
            const args = {
                pageType: pageType.CROSSGRADE,
                ctaText,
                productCurrentSku: this.currentPlan.product_code,
                productNewSku: selectedProduct.recurlyCode,
                userRegId: this.user.regID,
                userStatus: this.user.svod.package_status,
                userType: this.user.svod.status,
                pickPlanType: selectedProduct.planTier,
                pickPlanSku: selectedProduct.recurlyCode,
                purchaseProduct: selectedProduct.recurlyCode,
                purchaseProductName: selectedProduct.titleForTracking,
                purchasePrice: selectedProduct.price,
                productPricingPlan: selectedProduct.planType,
                purchasePaymentMethod: billingInfo.paymentMethod,
                // users forfeit trial when switching plans
                productOfferPeriod: selectedProduct.purchaseOfferPeriod,
                crossgradeConfirm: 1,
            };

            // Add coupon code if present
            if (this.promo && this.isValidPromoToSubmit()) {
                args.purchasePromoCode = this.promo;
            }

            this.$trackingService.trackAction(this.trackActionName.CROSSGRADE_COMPLETE, args);
        },
        trackCrossgradeFailure(error) {
            this.$trackingService.trackLog(logType.SWITCH_PLAN_ERROR, error);
        },
    },
};
